import apiClient from 'src/services/base'

export const deleteConfirmation = (): Promise<any> => {
  return apiClient
    .post('/users/delete-account-otp')
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const loginUser = async (
  email: string,
  password: string,
  temporaryConversationId?: string
): Promise<any> => {
  const response = await apiClient.post('auth/signin', {
    email,
    password,
    temporaryConversationId
  })

  return response
}

export const setGoogleCalendar = (body): Promise<any> => {
  return apiClient
    .post('/users/set-google-calendar', body)
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const cancelDeleteRequest = (): Promise<any> => {
  return apiClient
    .post('/users/cancel-delete-request')
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const verifyDeleteRequestOtp = (code: number): Promise<any> => {
  return apiClient
    .post('/users/verify-delete-otp', { verificationCode: code })
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const deleteApprenticeAccount = (dto): Promise<any> => {
  return apiClient
    .post('/users/delete-account', { reasons: dto })
    .then(resp => resp.data)
    .catch(err => err.response.data)
}
