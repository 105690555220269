import { Button } from '@/components/shared/basic/Buttons'
import { identifyUser } from '@/utils/hotjar'
import { segmentIdentifyCallback, segmentTrackCallback } from '@/utils/segment'
import validateEmail from 'validator/lib/isEmail'
import { login, loginRedirect, notify } from '@qureos/lib'
import {
  SegmentEventType,
  UserRole,
  ApprenticeProfile as User
} from '@qureos/types'
import * as Sentry from '@sentry/nextjs'
import { Dictionary, isEmpty, set } from 'lodash'
import moment from 'moment'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { createJobV2Clone } from 'src/services/jobs'
import { checkActiveSubscription } from 'src/services/subscription'
import { loginUser } from 'src/services/user'
import { useRecoilState } from 'recoil'
import { user as userAtom } from 'src/atoms'

export const useLogin = (showError = true) => {
  const router = useRouter()
  const [loading, setLoading] = useState<boolean>(false)
  const [, setUser] = useRecoilState<User>(userAtom)
  const redirectUrl = loginRedirect('get')

  const createInstantJob = async (id: string) => {
    try {
      const response = await createJobV2Clone(id)
      if (response) {
        notify({
          message: 'Job created successfully',
          type: 'success'
        })
        router.push(
          `/corporate/jobs/${response._id}?id=${response._id}&currentTab=1`
        )
      }
    } catch (error) {
      notify({
        message:
          error?.response?.data?.message ??
          'Unable to add candidates at the moment, please try again',
        type: 'error'
      })
    }
  }

  const onLogin = async (
    email: string,
    password: string,
    instantJobId?: string,
    apprenticeInstantJobId?: string,
    temporaryConversationId?: string
  ): Promise<Dictionary<string | JSX.Element>> => {
    setLoading(true)
    const errorMessages: Dictionary<JSX.Element | string> = {}

    try {
      const redirectUrl = loginRedirect('get') as string

      const errors = validateData(email, password)
      if (!isEmpty(errors)) {
        return errors
      }
      if (password.length > 48) {
        errorMessages['password'] = (
          <span>
            Password must be less than 48 characters. Please reset your password{' '}
            <Link href={'/forgot-password'}>
              <a className="underline">here</a>
            </Link>
          </span>
        )
      }
      // generic login call
      const response = await loginUser(email, password, temporaryConversationId)
      // extract the data and login the user
      const { data } = response

      if (isEmpty(data)) {
        throw Error()
      }

      if (
        isEmpty(data.token) &&
        data.role === UserRole.PROFESSIONAL &&
        data.verified === false
      ) {
        errorMessages['verification'] =
          'Email is not verified yet. Please check your mailbox and follow the instructions.'
        if (showError) {
          notify({
            message: 'Email is not verified yet. Please check your mailbox.',
            type: 'error'
          })
        }
        return errorMessages
      }

      setUser(data)
      login(data.token)
      await redirectUser(data, apprenticeInstantJobId, temporaryConversationId)
      if (instantJobId) {
        await createInstantJob(instantJobId)
      }
      segmentTrackCallback(SegmentEventType.LOGIN_BUTTON, {
        userId: data?._id?.toString(),
        dateTime: new Date(),
        fromUrl: router.asPath,
        redirectUrl: redirectUrl,
        email: email,
        role: data.role
      })
    } catch (error) {
      let message = 'Something went wrong, please try again.'

      if (error?.response?.data?.message === 'Invalid password') {
        message = 'Invalid password'
        errorMessages['password'] = 'Invalid password'
      } else if (error?.response?.data?.statusCode === 500) {
        message = error?.response?.data?.message
      }

      notify({ message: message, type: 'error' })
    } finally {
      setLoading(false)
    }

    return errorMessages
  }

  const redirectAdminUser = () => {
    router.push('/admin/dashboard')
  }

  const redirectApprenticeUser = async (
    data,
    apprenticeInstantJobId = undefined
  ) => {
    try {
      if (data.role === UserRole.ADMIN) {
        router.push('/admin/dashboard')
        return
      }

      if (data.role === UserRole.ENTERPRISE) {
        router.push('/home')
        return
      }

      if (data.verified) {
        const onboardingStep = data?.meta?.onboardingStep
        if (data.dateOfBirth || data.birthdate) {
          if (onboardingStep > 1 && apprenticeInstantJobId) {
            router.push('/jobs')
            return
          }
          if (data?.meta && (!onboardingStep || onboardingStep <= 1)) {
            router.push(
              `/apprentice-onboarding${
                apprenticeInstantJobId
                  ? `?apprenticeInstantJobId=${apprenticeInstantJobId}`
                  : ''
              }`
            )
            return
          } else {
            router.push(redirectUrl ?? '/jobs')
            return
          }
        } else {
          const redirectUrl = loginRedirect('get')
          loginRedirect('remove')

          router.push(
            `/verify-birthday${
              redirectUrl ? `?redirectUrl=${redirectUrl}` : ''
            }`
          )
          return
        }
      } else {
        router.push(
          `/verify-apprentice?operation=${encodeURIComponent(data.email)}`
        )
        return
      }
    } catch (error) {
      notify({ message: error, type: 'error' })
    }
  }

  const redirectCorporateUser = (data, temporaryConversationId = undefined) => {
    if (data?.defaultCompany?.isVfs) {
      router.push('/vfs/job-description-generator')
    } else if (temporaryConversationId) {
      router.push(`/corporate/jobs/iris/${temporaryConversationId}`)
    } else {
      router.push('/home')
    }
  }

  const redirectEnterpriseUser = () => {
    router.push('/home')
  }

  const redirectMentorUser = data => {
    let nextUrl = '/home'

    if (!data.verified) {
      nextUrl = `/mentor/verify?operation=${encodeURIComponent(data.email)}`
    }

    router.push(nextUrl)
  }

  const redirectUser = async (
    data,
    apprenticeInstantJobId = undefined,
    temporaryConversationId = undefined
  ) => {
    const segmentData = {
      name: data.name,
      email: data.email,
      userRole: data.role
    }

    if (data.role === UserRole.STUDENT && !isEmpty(data.token)) {
      set(
        segmentData,
        'dob',
        moment(data?.dateOfBirth).format('YYYY-MM-DD hh mm a')
      )
    }
    identifyUser(data._id, { email: data.email })
    segmentIdentifyCallback(data._id, {
      name: data.name,
      email: data.email,
      userRole: data.role
    })
    Sentry.setUser({
      id: data._id,
      email: data.email
    })
    try {
      // handle redirect based on user role
      switch (data.role) {
        case UserRole.ADMIN:
          redirectAdminUser()
          break
        case UserRole.ENTERPRISE:
          redirectEnterpriseUser()
          break
        case UserRole.STUDENT:
          await redirectApprenticeUser(data, apprenticeInstantJobId)
          break
        case UserRole.MENTOR:
          redirectMentorUser(data)
          break
        case UserRole.PROFESSIONAL:
          redirectCorporateUser(data, temporaryConversationId)
          break
      }
    } catch (error) {
      notify({
        message: error || 'Unable to redirect. Try again.',
        type: 'error'
      })
    }
  }

  const validateData = (
    email: string,
    password: string
  ): Dictionary<JSX.Element | string> => {
    const errorMessages: Dictionary<JSX.Element | string> = {}

    if (!email) {
      errorMessages['email'] = 'Please provide your email'
    } else if (!validateEmail(email as string)) {
      errorMessages['email'] = 'Please provide a valid email'
    }

    if (!password) {
      errorMessages['password'] = 'Please provide a Password'
    }

    return errorMessages
  }

  const loginButton = () => {
    return (
      <Button
        className="rounded-md font-medium text-sm leading-5"
        disabled={loading}
        loading={loading}
        rounded="normal"
        theme="normal"
        width="full"
      >
        Sign in
      </Button>
    )
  }

  return { loading, loginButton, onLogin, redirectUser }
}
