// import { jitsuAnalytics } from '@jitsu/js'
import { SegmentEventType } from '@qureos/types'

declare global {
  interface Window {
    analytics: any
  }
}

/*
const JITSU_HOST = process.env.JITSU_HOST
const JITSU_KEY = process.env.JITSU_KEY

export const jitsuAnalyticsClient = jitsuAnalytics({
  host: JITSU_HOST,
  writeKey: JITSU_KEY
})*/

export const segmentIdentifyCallback = (event, params = {}): void => {
  if (process.env.NODE_ENV === 'production') {
    window?.analytics?.identify(event, params)
    // jitsuAnalyticsClient.identify(event, params)
  }
}

export const segmentTrackCallback = (
  event: SegmentEventType,
  params = {}
): void => {
  if (process.env.APP_ENV === 'production') {
    window?.analytics?.track(event, params)
    // jitsuAnalyticsClient.track(event, params)
  } else if (
    ['development', 'dev', 'staging', 'staging-v1', 'staging-v2'].includes(
      process.env.APP_ENV.toLowerCase()
    )
  ) {
    /* for debugging in development mode */
    console.info('---------TRACKING SEGMENT EVENT---------')
    console.info(event, params)
  }
}
