export enum SegmentEventType {
  ADD_TO_GOOGLE_CALENDAR_EVENT = 'AddtoGoogleCalendarEvent',
  ADD_TO_OFFICE365_CALENDAR_EVENT = 'AddtoOffice365CalendarEvent',
  ADD_TO_OUTLOOK_CALENDAR_EVENT = 'AddtoOutlookCalendarEvent',
  ADD_TO_YAHOO_CALENDAR_EVENT = 'AddtoYahooCalendarEvent',
  CALENDLY = 'calendly',
  CANCEL_SUBSCRIPTION_PAYMENT_BUTTON = 'cancelSubscriptionPaymentButton',
  CASE_STUDY_APPLY = 'caseStudyApply',
  Clicked_Book_Session = 'clickedBookSession',
  Confirmed_Booking = 'confirmedBooking',
  DOWNLOAD_CALENDAR_EVENT = 'DownloadCalendarEvent',
  FREE_ENROLLMENT = 'freeEnrollment',
  FREQUENCY_TOGGLE = 'frequencyToggle',
  JOB_APPLY = 'jobApply',
  COMMUNITY_BUTTON = 'communityBtnClicked',

  JoinSessionClicked = 'joinSessionClicked',

  LOGIN_BUTTON = 'loginButton',
  LOGIN_WITH_GOOGLE_BUTTON = 'loginwithGoogleButton',
  LOGOUT_BUTTON = 'logoutButton',
  MENTOR_BOOKED = 'mentorBooked',
  PAID_ENROLLMENT = 'paidEnrollment',
  PAYMENT_INTENT = 'paymentIntent',
  PRODUCT_PURCHASED = 'Product Purchased',
  PROFILE_PIC_TO_PROFILE = 'profilePicToProfileRedirect',

  PUBLIC_JOB_REDIRECT = 'publicJobRedirect',

  SIDEBAR_PROFILE_NUDGE = 'sidebarProfileNudge',
  SIGNUP_LINK_CLICK = 'signupLinkClick',
  SIGNUP_WITH_GOOGLE_BUTTON = 'signupWithGoogleButton',
  START_FREE_TRIAL_BUTTON = 'startFreeTrialButton',
  Selected_Booking_Date = 'selectedBookingDate',
  Selected_Booking_Time = 'selectedBookingTime',
  TRY_MEMBERSHIP_BUTTON = 'tryMembershipButton',
  VERIFY_EMAIL_BUTTON = 'verifyEmailButton',
  Viewed_Bookings = 'viewedBookings',

  AI_DESCRIPTION_GENERATOR = 'aiJDRequested',
  AI_INTERVIEW_QUESTIONS_GENERATOR = 'aiInterviewQuestionsRequested',

  APPRENTICE_ONBOARDING_STEP_2 = 'userDepartment',
  APPRENTICE_ONBOARDING_COMPLETE = 'apprenticeOnboardingCompleted',

  CORPORATE_SIGNUP_CLICK = 'corporateSignupClick',
  // Corporate
  Corp_VisitedDashboard = 'visitedDashboard',
  Corp_ClickedCompleteProfileButton = 'clickedCompleteProfileButton',
  Corp_ClickedEnableNotificationButton = 'clickedEnableNotificationButton',
  Corp_ClickedJobSourceCandidate = 'clickedJobSourceCandidate',
  Corp_ClickedViewCandidates = 'clickedViewCandidates',
  Corp_ClickedCandidateInvite = 'clickedCandidateInvite',
  Corp_ClickedChatCompletion = 'Chatcompletion',
  Corp_ClickedSourceCandidate = 'clickedSourceCandidate',
  Corp_IrisJobCreationFailed = 'irisJobCreationFailed',
  Corp_OutreachModalUpgradeCta = 'clickedOutreachUpgradeButton',
  Corp_OutreachModalUpgradeNoteText = 'clickedOutreachUpgradeNoteText',
  Corp_OutreachModalCancelCta = 'clickedOutreachCancelButton',
  Corp_PlansModalCustomPlanContactUsCta = 'clickedPlansModalCustomPlanCta',
  Corp_PlansModalCancelCta = 'clickedPlansModalCancelCta',
  Corp_PlansModalPlanCardCta = 'clickedPlansModalPlanCardCta',
  Corp_PlanCheckoutDetailsModalPurchaseCta = 'clickedPlanCheckoutDetailsModalPurchaseCta',
  Corp_PlanCheckoutDetailsModalCancelCta = 'clickedPlanCheckoutDetailsModalCancelCta',
  Corp_StripeCheckoutFormCancelCta = 'clickedStripeCheckoutFormCancelCta',
  Corp_StripeCheckoutFormBuyCta = 'clickedStripeCheckoutFormBuyCta',
  Corp_PaymentSuccess = 'corpSubscriptionSuccess',
  Corp_ManagePlansBillingPageBuySubscriptionCta = 'clickedManagePlanBuySubscriptionCta',
  Corp_HistoryPageInSettingsPageBuyPlanCta = 'clickedBuyPlanCtaHistoryPageInManagePLan',
  Corp_ManagePlansBillingPageUpgradeCta = 'clickedManagePlanUpgradeCta',
  Corp_ManagePlansBillingPageCancelCta = 'clickedManagePlanCancelCta',
  Corp_ManagePlansBillingPageRestoreCta = 'clickedManagePlanRestoreCta',
  Corp_ManagePlansBillingPagePaymentDueCta = 'clickedManagePlanPaymentDueCta',
  Corp_ViewedCandidateFirstTime = 'viewedCandidateFirstTime',
  Corp_Thumbup = 'thumbUp',
  Corp_Thumbdown = 'thumbDown',
  Corp_ThumbupRemoved = 'thumbUpRemoved',
  Corp_ThumbdownRemoved = 'thumbDownRemoved',
  Corp_LeadGenJobsPageCta = 'clickedLeadGenJobsPageCta',
  Corp_LeadGenHireMeCta = 'clickedLeadGenHireMeCta',

  Corp_SignupNextClicked = 'corporateSignupNextClicked',
  Corp_SignupBackClicked = 'corporateSignupBackClicked',

  Corp_ClosedJob = 'corporateClosedJob',

  Corp_LinkedinOutreachUserSignin = 'corporateLinkedinSigninForOutreach',

  /* AI interview events */
  AI_INTERVIEW_SETUP_START = 'AIInterviewSetupStart',
  AI_INTERVIEW_STEP = 'AIInterviewStep',
  AI_INTERVIEW_READY = 'AIInterviewReady',
  AI_INTERVIEW_PUBLISHED = 'AIInterviewPublished',

  CANDIDATE_AI_INTERVIEW_INVITED = 'CandidateAIInterviewInvited',
  CANDIDATE_INTERVIEW_INVITED = 'CandidateInterviewInvited',

  CORPORATE_ADDED_NOTE = 'corporateAddedNote',
  CORPORATE_DELETED_NOTE = 'corporateDeletedNote',

  AI_SCREENING_TRIGGERED = 'AIScreeningStarted',
  AI_SCREENING_CRITERIA_ADDED = 'AIScreeningCriteriaAdded',
  AI_SCREENING_STARTED = 'AIScreeningClicked',
  AI_SCREENING_EDITED = 'AIScreeningEdited',
  AI_SCREENING_CRITERIA_DELETED = 'AIScreeningCriteriaDeleted',
  AI_SCREENING_CANDIDATE_EXPANDED = 'AIScreeningCandidateExpanded',

  // HIRING MANAGER
  HM_INVITE_CTA = 'HiringManagerInviteClicked',

  // VOICE INTRO
  VOICE_INTRO_STARTED = 'voiceIntroStarted',
  VOICE_INTRO_RECORDED = 'voiceIntroRecorded',
  VOICE_INTRO_DELETED = 'voiceIntroDeleted',
  VOICE_INTRO_SUBMITTED = 'voiceIntroSubmitted',
  VOICE_INTRO_SKIPPED = 'voiceIntroSkipped'
}
